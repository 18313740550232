export const CurrencyInfo = {
    KRW: {
        currency: "KRW",
        symbol: "₩",
        locale: "ko-KR",
    },
    // USD: {
    //     currency: "USD",
    //     symbol: "$",
    //     locale: "en-US",
    // },
    // CNY: {
    //     currency: "CNY",
    //     symbol: "元",
    //     locale: "zh-CN",
    // },
    JPY: {
        currency: "JPY",
        symbol: "￥",
        locale: "ja-JP",
    },
    VND: {
        currency: "VND",
        symbol: "đ",
        locale: "vi-VN",
    },
    MNT: {
        currency: "MNT",
        symbol: "₮",
        locale: "mn-MN",
    },
};

export const LanguageInfo = {
    ko: {
        title: "한국어",
    },
    en: {
        title: "English",
    },
    zh: {
        title: "中文",
    },
    jp: {
        title: "日本語",
    },
    vi: {
        title: "Tiếng Việt",
    },
    mn: {
        title: "Монгол хэл",
    },
};

export type LanguageType = keyof typeof LanguageInfo;
export type CurrencyType = keyof typeof CurrencyInfo;
